import { Modal } from "melodies-source/Modal";
import { useState } from "react";
import { TextInput } from "melodies-source/TextInput";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { Radio } from "melodies-source/Selectable";
import { useArtist } from "Providers/ArtistProvider";
import {
  getMetricName,
  ServiceWithConfig,
  socialPlatformConfigs,
} from "Routes/EditArtist/Socials/services";

type EditSocialModalProps = {
  service: ServiceWithConfig;
  onClose: () => void;
  isEdit?: boolean;
};

export const EditSocialModal = ({
  service,
  onClose,
  isEdit = false,
}: EditSocialModalProps) => {
  const { artist, setOverride } = useArtist();
  const [editService, setEditService] = useState<ServiceWithConfig | undefined>(
    service,
  );
  const [error, setError] = useState<Record<string, string> | null>(null);
  const metricKey = getMetricName(editService);

  const handleSave = () => {
    if (!editService[metricKey]) {
      setError((errors) => ({ ...errors, metric: "Please enter a value" }));
      return;
    }
    if (!(service.key in socialPlatformConfigs)) {
      return;
    }

    const updatedService = {
      ...artist?.services?.[service.key],
      [metricKey]: editService[metricKey],
      highlight_metric: metricKey,
      hidden: false,
    };

    if (service.fields) {
      const hasError = service.fields.some((field) => {
        if (!editService[field.name]) {
          setError((errors) => ({
            ...errors,
            [field.name]: "Please enter a value",
          }));
          return true;
        }
        return false;
      });
      if (hasError) return;

      service.fields.forEach((field) => {
        updatedService[field.name] = editService[field.name];
      });
    }

    setOverride(`followers.${service.key}`, updatedService);
    onClose();
  };

  const title = `${isEdit ? "Edit" : "Add"} ${service.label}`;

  return (
    <Modal isOpen onClose={onClose} variant="small" header={title}>
      <SearchContainer>
        {service.options.length > 1 && (
          <RadioContainer>
            {service.options.map((field) => (
              <Radio
                key={field}
                variant="radio"
                label={field}
                value={metricKey === field}
                onChange={() => {
                  setEditService((fields) => ({
                    ...fields,
                    highlight_metric: field,
                  }));
                  if (error) setError(null);
                }}
              />
            ))}
          </RadioContainer>
        )}
        <TextInput
          label={`${service.label} ${metricKey}`}
          placeholder={`Enter ${metricKey}`}
          value={editService?.[metricKey]}
          hasError={!!error?.metric}
          helperText={error?.metric}
          onChange={(v) => {
            setEditService((fields) => ({
              ...fields,
              [metricKey]: v,
            }));
            if (error) setError(null);
          }}
        />
        {editService.fields?.map((field, index) => (
          <TextInput
            key={`input-${index}`}
            label={field.label}
            placeholder={field.placeholder}
            value={editService?.[field.name]}
            hasError={!!error?.url}
            helperText={error?.url}
            onChange={(v) => {
              setEditService((fields) => ({
                ...fields,
                [field.name]: v,
              }));
              if (error) setError(null);
            }}
            style={{ marginTop: 10 }}
          />
        ))}
      </SearchContainer>
      <ButtonWrapper>
        <Button variant="basicOutline" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="basic" onClick={handleSave}>
          Save
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

const SearchContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-template-columns: 1fr;
  min-height: 20vh;
  align-content: flex-start;
`;
const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  & > div {
    align-items: center;
    text-transform: capitalize;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: auto;
  justify-content: flex-end;
  button {
    min-width: 120px;
  }
`;
