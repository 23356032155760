import React, { useEffect } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { Button } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import { Caption } from "melodies-source/Text";
import logoMax from "Components/Assets/logoMax.png";
import styled from "styled-components";
import LoginBackgroundImg from "Components/Assets/loginBgImg.jpg";
import { useUser } from "reactfire";

const provider = new GoogleAuthProvider();

export const Login = () => {
  const auth = getAuth();
  const { data: user, status } = useUser();
  const navigate = useNavigate();
  const signIn = () =>
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        result.user
          .getIdTokenResult()
          .then((res) => console.log("claims:", res.claims));

        if (user) {
          navigate("/admin");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });

  useEffect(() => {
    if (user && status === "success") {
      navigate("/admin");
    }
  }, [status]);

  return (
    <Container background={LoginBackgroundImg}>
      <Wrapper>
        <Logo src={logoMax} alt="" />
        <Button variant="primary" onClick={() => signIn()}>
          Login
        </Button>
        <Caption>Access your account via Google</Caption>
      </Wrapper>
    </Container>
  );
};
const Logo = styled.img`
  object-fit: cover;
  width: 200px;
  @media (max-width: 950px) {
  }
`;
const Container = styled.div<{ background: string }>`
  display: grid;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background-image: url(${({ background }) => background});
  background-size: cover;
`;
const Wrapper = styled.div`
  display: grid;
  width: 35%;
  min-width: 350px;
  max-width: 450px;
  place-items: center;
  grid-template-columns: 1fr;
  background: #fff;
  height: 350px;
  border-radius: 6px;
  padding: 30px;
  p {
    margin-top: -50px;
  }
  button {
    width: 100%;
  }
`;
