import { useState, useEffect } from "react";

const getStorageValue = (key: string, defaultValue: string) => {
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
};

type UseLocalStorage = [string, (value: string) => void];

export const useLocalStorage = (
  key: string,
  defaultValue: string,
): UseLocalStorage => {
  const [value, setValue] = useState<string>(
    getStorageValue(key, defaultValue),
  );

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};
