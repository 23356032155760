import { Button } from "melodies-source/Button";
import { Modal, ModalBackdrop } from "melodies-source/Modal";
import { Body1, H2 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { useState } from "react";
import styled from "styled-components";
import { FlexColumn } from "./Flex";
import logoMax from "Components/Assets/logoMax.png";
import { SvgMail } from "melodies-source/Svgs/Mail";
import { EmailCapture as EmailCaptureType } from "Providers/ListProvider/types";

interface EmailCaptureProps {
  onSubmit: (v: string) => void;
  emailCapture?: EmailCaptureType;
}

export const EmailCapture = ({ onSubmit, emailCapture }: EmailCaptureProps) => {
  const [value, setValue] = useState<string>();

  const isValidEmail = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = () => {
    if (isValidEmail(value)) {
      onSubmit(value);
    }
  };

  return (
    <StyledModal isOpen withBackdropClose={false} withCloseIcon={false}>
      <Wrapper>
        <Logo src={logoMax} />
        <FlexColumn gap="6px">
          <H2>
            {emailCapture?.title || (
              <>
                Please enter your email
                <br />
                to continue
              </>
            )}
          </H2>
          <Body1>
            {emailCapture?.message ||
              "Access to this artist list requires an email."}
          </Body1>
        </FlexColumn>
        <TextInput
          label="Email Address"
          placeholder="Please enter your email..."
          onChange={setValue}
          value={value}
          name="email"
          leftIcon={<SvgMail />}
        />
        <Button
          isDisabled={!isValidEmail(value)}
          onClick={handleSubmit}
          w100
          variant="basic"
        >
          Submit
        </Button>
      </Wrapper>
    </StyledModal>
  );
};

const Logo = styled.img`
  height: 48px;
`;

const StyledModal = styled(Modal)`
  ${ModalBackdrop} {
    opacity: 1;
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.8);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;

  ${H2} {
    font-weight: 700;
    text-align: center;
  }
  ${Body1} {
    text-align: center;
  }
`;
