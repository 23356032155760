import { Button } from "melodies-source/Button";
import { useState } from "react";
import styled, { css } from "styled-components";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import PencilSvg from "Components/Assets/Icons/pencil.svg?react";
import CircleChecked from "Components/Assets/Icons/CheckCircleFilled.svg?react";
import { useArtist } from "Providers/ArtistProvider";
import { H4, P } from "melodies-source/Text";
import { EditSocialModal } from "Components/EditSocialModal";
import {
  formatMetricValue,
  getListedServices,
  getMetricName,
  ServiceWithConfig,
} from "./services";

type EditService = {
  service?: ServiceWithConfig;
  isEdit?: boolean;
};

export const Socials = () => {
  const { artist, setOverride } = useArtist();
  const [editService, setEditService] = useState<EditService>({});

  const services = getListedServices(artist);

  return (
    <>
      <SocialContainer>
        {services.map((service) => {
          const isHidden = service.hidden;
          const highlightedMetric = service[getMetricName(service)];
          if (service.enableAdd && !highlightedMetric) {
            return (
              <SocialPlatform
                key={service.key}
                addSocial
                variant="secondary"
                onClick={() => {
                  setEditService({ service, isEdit: false });
                }}
              >
                <IconWrapper>{service.disabledIcon}</IconWrapper>
                <H4>
                  <SvgAddAlt style={{ width: 13, margin: "0 4px -5px 0" }} />
                  Add {service.label}
                </H4>
              </SocialPlatform>
            );
          }
          return (
            <SocialPlatform
              key={service.key}
              variant="secondary"
              onClick={() => {
                setOverride(`followers.${service.key}.hidden`, !isHidden);
              }}
              hidden={isHidden}
            >
              {!isHidden && <StyledCheckmark />}
              <IconWrapper>
                {isHidden ? service.disabledIcon : service.enabledIcon}
              </IconWrapper>
              <H4>{formatMetricValue(service[getMetricName(service)])}</H4>
              <P>{getMetricName(service)}</P>
              {!isHidden && service?.options.length > 1 && (
                <EditButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditService({
                      service,
                      isEdit: true,
                    });
                  }}
                >
                  <PencilSvg
                    style={{ color: "#0240fb", width: 17, marginRight: 5 }}
                  />
                  Edit
                </EditButton>
              )}
            </SocialPlatform>
          );
        })}
      </SocialContainer>
      {!!editService?.service && (
        <EditSocialModal
          onClose={() => setEditService({})}
          service={editService.service}
          isEdit={editService.isEdit}
        />
      )}
    </>
  );
};

const EditButton = styled.span`
  position: absolute;
  top: 105%;
  width: 100px;
  color: #0240fb;
  svg {
    color: #0240fb !important;
    margin-top: 6px;
    margin-bottom: -6px;
  }
  &:hover {
    background: transparent;
    box-shadow: none;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
  position: relative;
  @media (min-width: 550px) and (max-width: 950px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 550px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledCheckmark = styled(CircleChecked)`
  position: absolute;
  top: -8%;
  right: -8%;
`;

type SocialPlatformProps = {
  hidden?: boolean;
  addSocial?: boolean;
};

const SocialPlatform = styled(Button)<SocialPlatformProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  height: 135px;
  min-width: 100px;
  max-width: 300px;
  background: #ffffff;
  border-radius: 0px;
  border: 2px solid #0240fb;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  p {
    font-size: 13px;
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
  }
  ${({ hidden }) =>
    hidden
      ? css`
          border: 2px solid rgba(0, 0, 0, 0);
          color: #999999 !important;
          h4,
          p,
          svg {
            color: #999999 !important;
          }
          &:hover {
            border: 2px solid rgba(0, 0, 0, 0);
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.46);
          }
        `
      : css`
          border: 2px solid #0240fb;
          color: #999999 !important;
          &:hover {
            border: 2px solid #0240fb;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
          }
        `}

  ${({ addSocial }) =>
    addSocial &&
    css`
      background: #e3e9ed;
      border: 2px solid rgba(0, 0, 0, 0);
      h4 {
        color: #0240fb;
      }
      ${IconWrapper} {
        svg {
          color: #999999;
        }
      }
    `}
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 63px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
    color: #000;
  }
`;
