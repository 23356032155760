import { Modal } from "melodies-source/Modal";
import {
  Artist,
  ArtistOverride,
  ArtistReferences,
  ShareLists,
} from "../../Providers/ListProvider/types";
import { useEffect, useState } from "react";
import { AutocompleteAsync } from "melodies-source/Autocomplete";
import styled from "styled-components";
import { useUser } from "reactfire";
import { SvgCloseLarge } from "melodies-source/Svgs/CloseLarge";
import { H4, Subtitle2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import {
  setDoc,
  doc,
  getFirestore,
  DocumentReference,
} from "firebase/firestore";
import {
  IconContainer,
  SpinnerContainer,
  SpinnerRing,
} from "Components/LoadingScreen/SpinnerRing";
import { useParams } from "react-router-dom";
import PromisePool from "@supercharge/promise-pool";
import { useListContext } from "Hooks/useListContext";
import { Spinner } from "melodies-source/Spinner";
import { CustomOption } from "Components/PlacesAutocomplete";
import IndividualSvg from "Components/Assets/Icons/Individual.svg?react";
import CalendarLargeSvg from "Components/Assets/Icons/CalendarLarge.svg?react";
import DetailsSvg from "Components/Assets/Icons/Details.svg?react";
import ChartSvg from "Components/Assets/Icons/Chart.svg?react";
import GearSvg from "Components/Assets/Icons/Gear.svg?react";
import { getTopGenre } from "Providers/ArtistProvider/mergeOverrides";

const messageArray = [
  "Gathering Artist Info...",
  "Generating Artist Bio...",
  "Finding Tour Dates......",
  "Analyzing Audience and Markets... ",
  "Finishing Up...",
];
const iconArray = [
  <IndividualSvg />,
  <DetailsSvg />,
  <CalendarLargeSvg />,
  <ChartSvg />,
  <GearSvg />,
];

export const SearchArtistModal = ({ onClose, isOpen }) => {
  const [noResult, setNoResult] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any[]>();
  const [input, setInput] = useState<any>();
  const [loading, setLoading] = useState<boolean>();
  const [token, setToken] = useState<string>();
  const [step, setStep] = useState(0);
  const { status, data: user } = useUser();
  const { listId } = useParams();
  const { addArtists, list } = useListContext();
  const currentStep = Math.min(step, iconArray.length - 1);

  useEffect(() => {
    const getToken = async () => {
      const token = await user.getIdToken();
      setToken(token);
    };
    getToken();
  }, []);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setStep((step) => {
          if (step === iconArray.length - 1) {
            clearInterval(interval);
          }
          return step + 1;
        });
      }, 1100);
      return () => {
        setStep(0);
        clearInterval(interval);
      };
    }
  }, [loading]);

  const search = async (searchValue: string) => {
    setNoResult(false);
    if (searchValue && token) {
      try {
        const data = await fetch(`/api/artist/search?q=${searchValue}`, {
          headers: { Authorization: `bearer ${token}` },
        });
        const jsonData = await data.json();
        return jsonData?.map((artist) => ({
          ...artist,
          value: artist.name,
          label: artist.name,
          icon: <Avatar src={artist.imageUrl} />,
          caption: getTopGenre(artist?.genres),
        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handeClose = () => {
    setSearchData(null);
    onClose();
  };

  const onAddArtists = async () => {
    try {
      setLoading(true);
      const results = await PromisePool.for(searchData)
        .withConcurrency(10)
        .process(async (artist) => {
          const data = await fetch(`/api/artist/${artist.id}`, {
            method: "POST",
            headers: {
              Authorization: `bearer ${token}`,
              "content-type": "application/json",
            },
            body: JSON.stringify({
              brandName: list?.client,
              brandObjective: list?.campaignGoal,
              markets: list?.markets,
            }),
          });
          return data.json() as Promise<{
            artist: Artist;
            overrides: ArtistOverride;
          }>;
        });
      if (results.results.length) {
        await addArtists(results.results);
        const update = searchData.map((artist) => {
          return artist.id;
        });
        let order = [...(list?.order || []), ...update];
        const artistReferences = results.results.reduce((acc, result) => {
          const name = result.overrides.title || result.artist.name;
          if (name) {
            acc[result.artist.id] = {
              name: result.overrides.title || result.artist.name,
              image:
                result.overrides.image ||
                result.artist.image ||
                `/api/artist/image/${result.artist.id}`,
            };
          }
          return acc;
        }, {} as ArtistReferences);
        const artists = { ...list?.artists, ...artistReferences };
        await setDoc<ShareLists>(
          doc(
            getFirestore(),
            "ame_sharelists",
            listId,
          ) as DocumentReference<ShareLists>,
          {
            order,
            artists,
          },
          { merge: true },
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      handeClose();
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handeClose}
      header="Add Artist(s)"
      withBackdropClose={false}
    >
      <SearchContainer>
        <div>Artist Search</div>
        <AutocompleteAsync
          placeholder="Type Artist Name..."
          value={input || ""}
          text={input}
          setText={setInput}
          onChange={(v) => {
            setSearchData((prev) => [...(prev || []), v]);
            setInput("");
          }}
          getOptions={search}
          CustomOption={CustomOption}
        />
        {loading ? (
          <SpinnerWrapper>
            <SpinnerRing
              icon={iconArray[currentStep]}
              color="#0240FB"
              style={{ height: 150, margin: "5px 0" }}
            />
            <H4>{messageArray[currentStep]}</H4>
          </SpinnerWrapper>
        ) : (
          searchData?.map((artist) => {
            return (
              <ListItem key={artist?.id}>
                <img src={artist?.imageUrl} />
                <div>
                  <H4>{artist?.label}</H4>
                  <Subtitle2>{artist?.caption}</Subtitle2>
                </div>
                <SvgCloseLarge
                  onClick={() => {
                    let update = [...searchData];
                    update = update.filter(
                      (filterArtist) => artist?.id !== filterArtist?.id,
                    );
                    setSearchData(update);
                  }}
                />
              </ListItem>
            );
          })
        )}
      </SearchContainer>
      <ButtonWrapper>
        <Button variant="basicOutline" onClick={handeClose}>
          Cancel
        </Button>
        <Button
          isDisabled={loading}
          variant="basic"
          onClick={() => {
            onAddArtists();
          }}
        >
          {loading ? <Spinner /> : "Add"}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

const SearchContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-template-columns: 1fr;
  min-height: 40vh;
  align-content: flex-start;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: auto;
  justify-content: flex-end;

  button {
    width: 146px;
  }
`;

const ListItem = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  justify-content: flex-start;
  align-content: center;
  border-radius: 6px;
  border: 1px solid #cccccc;
  padding: 15px;
  img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 25px;
  }
  div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  svg {
    margin: auto;
  }
`;

const Avatar = styled.img`
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin: 0 12px 0 8px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  ${SpinnerContainer} {
    svg {
      height: 150px;
      width: 150px;
    }
  }
  ${IconContainer} {
    margin-top: 2px;
    svg {
      width: 110px;
      height: 110px;
    }
  }
`;
