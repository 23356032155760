import { ListActions } from "Components/ListActions";
import { ListItem } from "Components/ListItem";
import Pagination from "Components/Pagination";
import { H1, Subtitle1 } from "melodies-source/Text";
import { useEffect, useState } from "react";
import styled from "styled-components";
import heroImageMobile from "Components/Assets/adminHeroMobile.jpg";
import heroImage from "Components/Assets/adminHero.jpg";
import { collection, Query, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import ListModal from "./ListModal";
import { ShareLists } from "Providers/ListProvider/types";

export const AdminListView = () => {
  const [listData, setListData] = useState([]);
  const [search, setSearch] = useState<string>();
  const [filters, setFilters] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<string>("date_desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeListItem, setActiveListItem] = useState<ShareLists | null>(null);
  const [legacyId, setLegacyId] = useState<string>();
  const navigate = useNavigate();

  const RESULTS_PER_PAGE = 20;

  const { data: user } = useUser();

  const { status, data } = useFirestoreCollectionData<ShareLists>(
    query(
      collection(useFirestore(), "ame_sharelists"),
      where("marketType", "!=", null),
    ) as Query<ShareLists>,
    {
      idField: "id",
    },
  );

  useEffect(() => {
    (async () => {
      if (!user?.uid) return;
      // get legacy user id from user email
      await fetch("/api/users/legacy", {
        headers: {
          Authorization: await user.getIdToken(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.success) setLegacyId(res.uid);
          else console.log(`Error getting legacy user token: ${res?.error}`);
        })
        .catch((err: any) => console.log(err));
    })();
  }, [user]);

  useEffect(() => {
    setListData(searchAndFilterData());
    setCurrentPage(1);
  }, [data]);

  useEffect(() => {
    setListData(searchAndFilterData());
    setCurrentPage(1);
  }, [search, filters, sortBy]);

  const normalizeString = (s: string) =>
    ("" + s).normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  const searchAndFilterData = () => {
    const searchEx = search ? new RegExp(search, "gi") : null;
    return data
      ?.filter(
        (item) =>
          !search ||
          (search &&
            (item.campaign?.match(searchEx) || item.client?.match(searchEx))) ||
          item.createdBy?.name?.match(searchEx) ||
          Object.values(item.artists || {})
            ?.map((a) => a.name)
            ?.join(" ")
            .match(searchEx),
      )
      .filter((item) => {
        if (!filters?.length) return true;
        let matched = false;
        filters.forEach((f) => {
          switch (f) {
            case "me":
              matched =
                item.createdBy?.uid === user.uid ||
                "" + item.createdBy?.uid === legacyId;
              break;
            case "published":
              matched = item.published;
              break;
          }
        });
        return matched;
      })
      .sort((a, b) => {
        switch (sortBy) {
          case "recent":
            return (
              DateTime.fromJSDate(b?.updatedAt?.toDate()).toMillis() -
              DateTime.fromJSDate(a?.updatedAt?.toDate()).toMillis()
            );
          case "date_desc":
            return (
              DateTime.fromJSDate(b?.createdAt?.toDate()).toMillis() -
              DateTime.fromJSDate(a?.createdAt?.toDate()).toMillis()
            );
          case "date_asc":
            return (
              DateTime.fromJSDate(a?.createdAt?.toDate()).toMillis() -
              DateTime.fromJSDate(b?.createdAt?.toDate()).toMillis()
            );
          case "client":
            return normalizeString(a.client).localeCompare(
              normalizeString(b.client),
            );
          case "campaign":
            return normalizeString(a.campaign).localeCompare(
              normalizeString(b.campaign),
            );
        }
      });
  };

  const indexStart = (currentPage - 1) * RESULTS_PER_PAGE;
  const indexEnd = Math.min(
    indexStart + RESULTS_PER_PAGE,
    listData?.length || 0,
  );

  return (
    <>
      <Banner background={heroImage} backgroundMobile={heroImageMobile}>
        <H1>Artist Share Lists</H1>
        <Button
          variant="basic"
          w={200}
          onClick={() => navigate("/admin/create-list")}
        >
          + Create List
        </Button>
      </Banner>
      <ListActions
        onSearch={(value: string) => setSearch(value)}
        onFilter={(value: string[]) => setFilters(value)}
        onSort={(value: string) => setSortBy(value)}
      />
      <ListWrapper>
        <ListContainer>
          <ListHeader>
            <Subtitle1>Client</Subtitle1>
            <Subtitle1>Campaign</Subtitle1>
            <Subtitle1 style={{ paddingLeft: 10 }}>Artists</Subtitle1>
            <Subtitle1>Type</Subtitle1>
            <Subtitle1>Created</Subtitle1>
            <Subtitle1>Created By</Subtitle1>
            <Subtitle1 style={{ placeSelf: "center" }}>Published</Subtitle1>
          </ListHeader>
          {listData?.slice(indexStart, indexEnd).map((data, index) => (
            <ListItem
              onClick={() => setActiveListItem(data)}
              {...data}
              data={data}
              key={`__sl_item__${index}`}
            />
          ))}
        </ListContainer>
        {listData?.length > RESULTS_PER_PAGE && (
          <Pagination
            selectedPage={currentPage}
            resultsPerPage={RESULTS_PER_PAGE}
            totalResults={listData?.length}
            onSetPage={setCurrentPage}
          />
        )}
      </ListWrapper>
      {activeListItem && (
        <ListModal
          isOpen={Boolean(activeListItem)}
          setIsOpen={() => setActiveListItem(null)}
          {...activeListItem}
        />
      )}
    </>
  );
};

const Banner = styled.div<{ backgroundMobile: string; background: string }>`
  width: 100%;
  height: 220px;
  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: grid;
  align-items: center;
  padding: 20px;
  h1 {
    color: #fff;
    width: clamp(900px, 80vw, 1170px);
    margin: auto;
    font-weight: 800;
  }
  button {
    display: none;
  }
  @media (max-width: 950px) {
    background-image: ${({ backgroundMobile }) => `url(${backgroundMobile})`};
    background-position: center;
    place-items: center;
    padding: 15px;
    h1 {
      width: unset;
    }
    button {
      display: block;
      width: 100%;
      max-width: 490px;
    }
  }
`;
const ListWrapper = styled.div`
  width: clamp(900px, 80vw, 1170px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;
const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  ${({ theme }) => theme.mediaQueries.desktop} {
    gap: 0;
  }
`;
const ListHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr;
  align-content: center;
  gap: 10px;
  text-transform: uppercase;
  h4 {
    color: #999999 !important;
    font-size: clamp(12px, 1.3vw, 14px);
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;
