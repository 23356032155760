import styled from "styled-components";
import { Button, ButtonType } from "../Button";
import Portal from "../common/Portal";
import { SvgCloseLarge } from "../Svgs/CloseLarge";
import { Body1, H2 } from "../Text";
import { colors } from "../Theme/colors";
import { Div } from "../utils";
import { useClickOutside, useOverlay } from "../utils/hooks";
import { useState } from "react";

export const ModalContainer = styled(Div)`
  top: 0px;
  left: 0px;
  margin: 0px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const ModalBackdrop = styled(Div)`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: black;
  opacity: 0.8;
  pointer-events: none;
`;

export const ModalWrapper = styled(Div)`
  background: ${(props) => props.theme.colors.white};
  padding: 38px 48px;
  border-radius: 0;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  margin-top: 8vh;
  margin-bottom: 100px;
  z-index: 101;
  ${(props) => props.theme.mediaQueries.mobile} {
    width: 90vw;
    padding: 30px;
  }
  ${(p) => p.theme.css.scrollbarDark};
`;

export const ModalCloseIcon = styled(Div)`
  height: 0;
  display: flex;
  flex-direction: row-reverse;
`;

const ModalTypes = ["small", "medium", "large"] as const;
export type ModalType = (typeof ModalTypes)[number];

type ModalWidths = {
  [K in ModalType]?: any;
};
const modalWidths: ModalWidths = {
  small: 476,
  medium: 676,
  large: 876,
};

type ModalConfirmation = {
  isDisabled?: boolean;
  label?: string;
  variant?: ButtonType;
  onConfirm: () => void;
};

type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  children?: any;
  header?: string;
  variant?: ModalType;
  body?: string;
  withCloseIcon?: boolean;
  withBackdropClose?: boolean;
  className?: string;
  confirmation?: ModalConfirmation;
};
export const Modal = ({
  isOpen = false,
  onClose = () => {},
  header,
  body,
  variant = "small",
  children,
  withCloseIcon = true,
  withBackdropClose = true,
  confirmation,
  ...props
}: ModalProps) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement>();
  useOverlay(!!isOpen);
  useClickOutside(containerRef, onClose, !withBackdropClose);

  const headerUI = (
    <Div spaceBetween>
      <H2 mr={12}>{header}</H2>
      {withCloseIcon && (
        <ModalCloseIcon>
          <Div clickable onClick={onClose} c={colors.black60}>
            <SvgCloseLarge />
          </Div>
        </ModalCloseIcon>
      )}
    </Div>
  );

  const confirmationUI = confirmation && (
    <Div justifyEnd mt={32}>
      <Div dflex>
        <Button w={82} variant="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={!!confirmation?.isDisabled || false}
          variant={confirmation.variant}
          ml={20}
          dInline
          onClick={() => {
            confirmation?.onConfirm();
            onClose();
          }}
        >
          {confirmation.label || "Confirm"}
        </Button>
      </Div>
    </Div>
  );

  return (
    <>
      {isOpen && (
        <Portal>
          <ModalContainer {...props}>
            <ModalBackdrop />
            <ModalWrapper w={modalWidths[variant]} ref={setContainerRef}>
              {headerUI}
              <Div>
                {body && <Body1 mt={20}>{body}</Body1>}
                {children}
              </Div>
              {confirmationUI}
            </ModalWrapper>
          </ModalContainer>
        </Portal>
      )}
    </>
  );
};
