import { Button } from "melodies-source/Button";
import { H4, P } from "melodies-source/Text";
import { useArtist } from "Providers/ArtistProvider";
import {
  formatMetricValue,
  getMetricName,
  ServiceWithConfig,
  socialPlatformConfigs,
} from "Routes/EditArtist/Socials/services";
import styled from "styled-components";

export const Socials = () => {
  const { artist } = useArtist();

  const listedServices: ServiceWithConfig[] = Object.entries(
    artist?.services || {},
  )
    .reduce((acc, [key, service]) => {
      const hidden = artist?.followers?.[key]?.hidden === true;
      const mergedService: ServiceWithConfig = {
        ...service,
        ...socialPlatformConfigs[key],
      };
      const highlightedMetric = service[getMetricName(mergedService)];
      if (
        hidden ||
        !highlightedMetric ||
        (key === "soundcloud" && service.tiktok)
      ) {
        return acc;
      }
      acc.push({ ...mergedService });
      return acc;
    }, [] as ServiceWithConfig[])
    .sort((a, b) => a.order - b.order);

  return (
    <SocialContainer>
      {listedServices.map((service) => {
        return (
          <SocialCard
            variant="secondary"
            onClick={() => window.open(service.url)}
          >
            <IconWrapper>{service.enabledIcon}</IconWrapper>
            <H4>{formatMetricValue(service[getMetricName(service)])}</H4>
            <P>{getMetricName(service)}</P>
          </SocialCard>
        );
      })}
    </SocialContainer>
  );
};

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
  position: relative;

  @media (min-width: 550px) and (max-width: 950px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 550px) {
    //  max-width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const SocialCard = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  height: fit-content;
  min-width: 100px;
  max-width: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 0px;
  border: 0px solid lightgrey;
  ${P} {
    font-size: 13px;
    text-transform: capitalize;
  }
  &:hover {
    border: none;
  }
`;

const IconWrapper = styled.div`
  width: 44px;
  height: 63px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
  }
`;
