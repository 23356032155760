import { useListContext } from "Hooks/useListContext";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import { ShareLists } from "Providers/ListProvider/types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EditListForm } from "Routes/CreateList";
import styled from "styled-components";
import { setDoc, doc, getFirestore, Timestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { isEqual } from "lodash";
import { ConfirmUpdateModal } from "Providers/ListProvider/ConfirmUpdateModal";
import { getDateTime } from "utils/date";

export type DiffCheckProps = {
  attributes?: boolean;
  markets?: boolean;
};

export const EditListModal = ({ isOpen, onClose }) => {
  const { list } = useListContext();
  const { listId } = useParams();
  const [localList, setLocalList] = useState<ShareLists>(list);
  const [confirmModal, setConfirmModal] = useState<{
    markets?: boolean;
    attributes?: boolean;
  } | null>(null);

  useEffect(() => {
    if (list) {
      setLocalList(list);
    }
  }, [isOpen]);

  const handleSaveUpdatedList = async () => {
    if (localList) {
      const currAttributes = {
        client: localList?.client,
        campaignGoal: localList?.campaignGoal,
        targetPersona: localList?.targetPersona,
      };
      const newAttributes = {
        client: list?.client,
        campaignGoal: list?.campaignGoal,
        targetPersona: list?.targetPersona,
      };
      const attributes = !isEqual(currAttributes, newAttributes);
      const markets = !isEqual(localList?.markets, list?.markets);
      try {
        await setDoc(
          doc(getFirestore(), "ame_sharelists", listId),
          {
            ...localList,
            ...(localList.expiresAt && {
              expiresAt: Timestamp.fromDate(
                getDateTime(localList.expiresAt)?.toJSDate(),
              ),
            }),
          },
          { merge: true },
        );
        toast.success("List has been saved.");
        if (attributes || markets) {
          setConfirmModal({ attributes: attributes || markets, markets });
        }
        onClose();
      } catch (err) {
        console.log(err);
        toast.error("There was an issue saving your list. Please try again.");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        variant="medium"
        header="Edit List Details"
        withBackdropClose={false}
      >
        <EditListForm
          list={localList}
          setListDetails={setLocalList}
          listId={listId}
          fullWidth
          style={{ marginTop: 20 }}
        />
        <ButtonWrapper>
          <Button
            variant="basicOutline"
            onClick={() => {
              onClose();
            }}
            w={140}
          >
            Cancel
          </Button>
          <Button
            variant="basic"
            onClick={() => {
              handleSaveUpdatedList();
              onClose();
            }}
            w={140}
          >
            Save
          </Button>
        </ButtonWrapper>
      </Modal>
      {confirmModal && (
        <ConfirmUpdateModal
          diffCheck={confirmModal}
          isOpen={Boolean(confirmModal)}
          onClose={() => {
            setConfirmModal(null);
          }}
        />
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: -50px;
  justify-content: flex-end;
`;
