export const clearFirestoreCache = () => {
  const map = globalThis["_reactFirePreloadedObservables"];

  if (map instanceof Map) {
    Array.from(map.keys()).forEach((key: string) => {
      if (typeof key === "string" && key.includes("firestore")) {
        map.delete(key);
      }
    });
  } else {
    console.error("Expected _reactFirePreloadedObservables to be a Map.");
  }
};
