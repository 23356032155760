import { InternalRoutes as Routes } from "./Routes";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { getFirestore } from "firebase/firestore";
import { AuthProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { getAuth } from "firebase/auth";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

gsap.registerPlugin(useGSAP);

function App() {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <APIProvider
          apiKey={import.meta.env.VITE_GOOGLE_MAPS_APIKEY}
          libraries={["maps", "marker", "places", "geocoding"]}
        >
          <Routes />
          <ToastContainer position="bottom-right" />
        </APIProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
