import { doc, setDoc } from "firebase/firestore";
import { Button } from "melodies-source/Button";
import { Body1 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { EmailCapture } from "Providers/ListProvider/types";
import { useState } from "react";
import { toast } from "react-toastify";
import { useFirestore } from "reactfire";
import styled from "styled-components";

interface EditMessageProps {
  listId: string;
  emailCapture?: EmailCapture;
  onClose: (v: boolean) => void;
}

export const EditMessage = ({
  listId,
  emailCapture,
  onClose,
}: EditMessageProps) => {
  const [state, setState] = useState({
    title: emailCapture?.title || "",
    message: emailCapture?.message || "",
  });
  const db = useFirestore();

  const handleSave = async () => {
    try {
      await setDoc(
        doc(db, "ame_sharelists", listId),
        {
          emailCapture: state,
        },
        { merge: true },
      );
      toast.success("Message updated successfully");
      onClose(true);
    } catch (error) {
      console.error(error);
      toast.error("There was an error updating the message");
    }
  };

  return (
    <Container>
      <Body1>
        Make changes to the message displayed to users required to enter their
        email:
      </Body1>
      <TextInput
        label="Title"
        placeholder="Please enter your email to continue..."
        value={state.title}
        onChange={(v) => setState((prev) => ({ ...prev, title: v }))}
      />
      <TextInput
        label="Message"
        placeholder="Access to this list requires an email..."
        value={state.message}
        onChange={(v) => setState((prev) => ({ ...prev, message: v }))}
      />
      <Actions>
        <Button variant="basicOutline" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="basic" onClick={handleSave}>
          Save
        </Button>
      </Actions>
    </Container>
  );
};

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  button {
    width: 120px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 6px;
`;
