import { useState } from "react";
import { Modal, ModalWrapper } from "melodies-source/Modal";
import { ShareLists } from "Providers/ListProvider/types";
import { Div } from "melodies-source/utils";
import { Body2, H3, P, Selected } from "melodies-source/Text";
import City from "Components/Assets/Icons/City.png";
import Calendar from "Components/Assets/Icons/Calendar.png";
import styled, { css } from "styled-components";
import { Button } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { DateTime } from "luxon";
import { useFirestore } from "reactfire";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import { SvgOpen } from "melodies-source/Svgs/Open";
import { toast } from "react-toastify";
import { Analytics } from "./Analytics";
import { Switch } from "melodies-source/Switch";
import { FlexColumn, FlexRow } from "Components/Flex";
import { SvgPencilEdit } from "assets/svg";
import { EditMessage } from "./EditMessage";

const ModalViews = ["default", "analytics"] as const;
export type ModalView = (typeof ModalViews)[number];

type ListModalProps = {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
} & Partial<ShareLists>;

const ListModal = ({
  setIsOpen,
  client,
  order,
  campaign,
  campaignGoal,
  createdAt,
  published,
  imageUrl,
  id,
  emailCapture,
  isOpen,
}: ListModalProps) => {
  const [view, setView] = useState<ModalView>("default");
  const [emailRequirement, setEmailRequirement] = useState<boolean>(
    emailCapture?.enabled || false,
  );
  const [editMessage, setEditMessage] = useState<boolean>(false);
  const navigate = useNavigate();
  const db = useFirestore();

  const handlePublish = async (listId: string) => {
    if (listId) {
      await setDoc(
        doc(db, "ame_sharelists", listId),
        {
          published: published ? false : true,
        },
        { merge: true },
      );
    }
    navigate("/admin");
  };

  const handleUpdate = async (state: boolean) => {
    try {
      await setDoc(
        doc(db, "ame_sharelists", id),
        { emailCapture: { enabled: state } },
        { merge: true },
      );
      toast.success(
        `Email requirement has been ${state ? "enabled" : "disabled"}.`,
        { type: state ? "success" : "info" },
      );
    } catch (error) {
      console.error(error);
      toast.error("Failed to update email requirement.");
    }
  };

  const getShareUrl = (id: string) => {
    const shareUrl = new URL(id, window.location.origin);
    return shareUrl.toString();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      variant="small"
      header={`${campaign}`}
      withBackdropClose={!editMessage}
    >
      {view === "analytics" ? (
        <FlexColumn gap="8px" style={{ paddingTop: 20 }}>
          <H3 style={{ fontWeight: 600 }}>All Views</H3>
          <Analytics
            id={id}
            setView={setView}
            view="analytics"
            emailRequirement={emailRequirement}
          />
          <Button
            variant="basic"
            style={{ width: 120, alignSelf: "flex-end", marginTop: 12 }}
            onClick={() => setView("default")}
          >
            Back
          </Button>
        </FlexColumn>
      ) : (
        <>
          <Container>
            <BrandDetails>
              <InfoContainer hasSummary={false}>
                <LogoBox hasSummary={false} isOval={false}>
                  <BrandLogo src={imageUrl} isContain={true} />
                </LogoBox>
                <BrandInfo>
                  <Div alignCenter>
                    <Icon src={City} />
                    <P>{client}</P>
                  </Div>
                  <TimeContainer>
                    <Icon src={Calendar} />
                    <P>
                      {DateTime.fromJSDate(createdAt?.toDate()).toFormat(
                        "LLLL dd, yyyy",
                      )}
                    </P>
                  </TimeContainer>
                  <TimeContainer>
                    <Icon src={Calendar} />
                    <P>{order?.length || 0} Artists</P>
                  </TimeContainer>
                </BrandInfo>
              </InfoContainer>
              <Body2>{campaignGoal}</Body2>
              <MiddleActions>
                <Button
                  variant="basic"
                  onClick={() => {
                    navigator.clipboard.writeText(getShareUrl(id));
                    toast.success("Copied to clipboard.");
                  }}
                  gap="8px"
                  leftIcon={<SvgCopy />}
                >{`share.max.live/${id}`}</Button>
                <Button
                  variant="basicOutline"
                  rightIcon={<SvgOpen />}
                  gap="8px"
                  onClick={() => window.open(getShareUrl(id), "_blank")}
                >
                  Preview List
                </Button>
                <FlexColumn xStart gap="6px">
                  <FlexRow gap="8px">
                    <Switch
                      onChange={(v) => {
                        setEmailRequirement(v);
                        handleUpdate(v);
                      }}
                      value={emailRequirement}
                    />
                    <Selected>Require Email to Open</Selected>
                  </FlexRow>
                  {emailRequirement && (
                    <Button
                      variant="basicText"
                      gap="8px"
                      leftIcon={<SvgPencilEdit />}
                      style={{ marginLeft: 48 }}
                      onClick={() => setEditMessage(true)}
                    >
                      Edit Message
                    </Button>
                  )}
                </FlexColumn>
              </MiddleActions>
            </BrandDetails>
            <Analytics
              id={id}
              setView={setView}
              view="default"
              emailRequirement={emailRequirement}
            />
          </Container>
          <ButtonContainer>
            <Button
              variant="basicOutline"
              style={{ width: 120 }}
              onClick={() => navigate(`/admin/${id}`)}
            >
              Edit List
            </Button>
            <Button
              variant="basic"
              style={{ width: 120 }}
              onClick={() => {
                handlePublish(id);
                setIsOpen(false);
              }}
            >
              {published ? "Unpublish" : "Publish List"}
            </Button>
          </ButtonContainer>
          {editMessage && (
            <StyledModal
              header="Edit Message"
              isOpen={editMessage}
              onClose={() => setEditMessage(false)}
              withBackdropClose={false}
            >
              <EditMessage
                listId={id}
                onClose={() => setEditMessage(false)}
                emailCapture={emailCapture}
              />
            </StyledModal>
          )}
        </>
      )}
    </StyledModal>
  );
};

export default ListModal;

const StyledModal = styled(Modal)`
  ${ModalWrapper} {
    h2 {
      margin-top: -5px;
      font-weight: 800;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  gap: 7px;
  & > h2 {
    font-weight: 800;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
`;

export const BrandDetails = styled.div`
  display: grid;
  width: 100%;
  margin-bottom: 10px;
  grid-template-columns: 1fr;
  opacity: 1;
  z-index: 2;
`;

export const LogoBox = styled.div<{ hasSummary: boolean; isOval: boolean }>`
  display: flex;
  background-color: #fff;
  aspect-ratio: 1/1;
  width: 140px;
  border-radius: 0px;
  box-shadow: 0px 0px 15px 10px #e7e7e7;
  overflow: hidden;

  ${(props) => props.hasSummary && css``}

  ${(props) =>
    props.isOval &&
    css`
      border-radius: 50%;
    `}
  @media (max-width: 950px) {
    min-width: unset;
    min-height: unset;
    width: 132px;
    margin-bottom: 10px;
  }
`;
export const BrandLogo = styled.img<{ isContain: boolean }>`
  width: 100%;
  display: flex;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1/1;
  ${(props) =>
    props.isContain &&
    css`
      object-fit: contain;
    `}
`;

export const BrandInfo = styled.div`
  display: flex;
  flex-direction: column !important;
  gap: 15px;
  margin: auto 0;
`;
export const InfoContainer = styled.div<{ hasSummary: boolean }>`
  display: flex;
  margin: 15px 0;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;

  div,
  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }
  div {
    display: flex;
  }
`;
export const TimeContainer = styled.div`
  position: relative;
  &:hover {
    span {
      visibility: visible;
    }
  }
  ${(p) => p.theme.mediaQueries.mobile} {
  }
`;
export const Icon = styled.img`
  border-radius: 13px;
  width: 26px;
  border: 1px solid #000;
  padding: 2px;
  margin-right: 12px;
  @media (max-width: 950px) {
    width: 22px;
    padding: 1px;
  }
`;

const MiddleActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0 10px;
`;
