import { getAuth, GoogleAuthProvider, signOut } from "firebase/auth";
import { Button } from "melodies-source/Button";
import { useNavigate } from "react-router-dom";
import { Body1 } from "melodies-source/Text";
import logoMax from "Components/Assets/logoMax.png";
import styled from "styled-components";
import LoginBackgroundImg from "Components/Assets/loginBgImg.jpg";
import { clearFirestoreCache } from "Modules/clearFirestoreCache";

const provider = new GoogleAuthProvider();

export const Page403 = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const logout = () =>
    signOut(auth)
      .then(() => {
        clearFirestoreCache();
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  return (
    <Container background={LoginBackgroundImg}>
      <Wrapper>
        <Logo src={logoMax} alt="" />
        <Body1>
          You do not have permission to view this page.
          <br />
          Please log out and sign in with an authorized account.
        </Body1>
        <Button variant="primary" onClick={logout}>
          Logout
        </Button>
      </Wrapper>
    </Container>
  );
};
const Logo = styled.img`
  object-fit: cover;
  width: 200px;
  @media (max-width: 950px) {
  }
`;
const Container = styled.div<{ background: string }>`
  display: grid;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background-image: url(${({ background }) => background});
  background-size: cover;
`;
const Wrapper = styled.div`
  display: grid;
  width: 35%;
  min-width: 350px;
  max-width: 450px;
  place-items: center;
  grid-template-columns: 1fr;
  background: #fff;
  height: 350px;
  border-radius: 6px;
  padding: 30px;
  text-align: center;
  ${Body1} {
    color: #333;
  }
  button {
    width: 100%;
  }
`;
