import { Body2, Subtitle1, Subtitle2 } from "melodies-source/Text";
import { AnalyticsEventDocument } from "Providers/AnalyticsProvider";
import styled, { css } from "styled-components";
import ViewsSvg from "Components/Assets/Icons/views.svg?react";
import { Spinner } from "melodies-source/Spinner";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import {
  collection,
  CollectionReference,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useMemo } from "react";
import { Button } from "melodies-source/Button";
import { ModalView } from ".";
import { getScrollBarCss } from "melodies-source/Theme/appCss/globalCss";
import { DateTime } from "luxon";

interface AnalyticsProps {
  id: string;
  setView: (v: ModalView) => void;
  view: ModalView;
  emailRequirement?: boolean;
}

export const Analytics = ({
  id,
  setView,
  view,
  emailRequirement,
  ...props
}: AnalyticsProps) => {
  const firestore = useFirestore();

  const ref = useMemo(
    () =>
      collection(
        firestore,
        "ame_sharelists",
        id,
        "analytics",
      ) as CollectionReference<AnalyticsEventDocument>,
    [id],
  );

  const { data: analyticsData, status } = useFirestoreCollectionData(
    query(
      ref,
      where("type", "==", "view:list"),
      orderBy("createdAt", "desc"),
      limit(10),
    ),
    { idField: "id" },
  );

  // We do this filtering here because of Firestore's filtering and inequality
  // rules (limitations)o
  const data =
    analyticsData
      ?.filter((data) => !!data.geo.city && !!data.geo.state)
      .slice(0, view === "default" ? 5 : undefined) || [];

  if (data === undefined || status === "loading") {
    return <Spinner />;
  }

  return (
    <AnalyticsContainer {...props}>
      <Header>
        <Subtitle1>
          {data.length} Total
          {emailRequirement ? " Viewer" : " View"}
          {data.length === 1 ? "" : "s"}
        </Subtitle1>
        <ViewsSvg />
      </Header>
      <Subtitle1>Recent {emailRequirement ? "Viewers" : "Views"}</Subtitle1>
      <List>
        {data.length === 0 && (
          <ListItem>
            <Subtitle2>No Data</Subtitle2>
          </ListItem>
        )}
        <ScrollContainer expand={view === "analytics"}>
          {data.map((doc) => {
            const location = [doc.geo.city, doc.geo.state].join(", ");
            return (
              <ListItem key={doc.id}>
                <Subtitle2>
                  {!emailRequirement ? location : doc.email || location}
                </Subtitle2>
                <Body2>
                  {DateTime.fromJSDate(doc.createdAt.toDate())
                    .toLocal()
                    .toFormat("L/dd/yy h:mma ZZZZ")}
                </Body2>
              </ListItem>
            );
          })}
        </ScrollContainer>
      </List>
      {view === "default" && (
        <Button variant="basicText" onClick={() => setView("analytics")}>
          View All
        </Button>
      )}
    </AnalyticsContainer>
  );
};

const ScrollContainer = styled.div<{ expand: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  padding-right: 16px;
  overflow-y: scroll;
  ${getScrollBarCss()}

  ${(p) =>
    p.expand &&
    css`
      height: 20vh;
    `};
`;

const AnalyticsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f7f9;
  gap: 7px;
  & > h4 {
    margin-right: auto;
    padding-left: 10px;
  }

  button {
    margin-bottom: 12px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  background-color: #e0e7eb;
  padding: 5px 10px;
`;

const ListItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 50%;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  ${Subtitle2} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ${Subtitle2},
  ${Body2} {
    color: #000000;
  }
  ${Body2} {
    display: flex;
    justify-content: flex-end;
  }
`;

const List = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 6px 10px 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 7px;
`;
