import { Select } from "Components/Select";
import { Modal } from "melodies-source/Modal";
import { H2 } from "melodies-source/Text";
import { Textarea } from "melodies-source/Textarea";
import { TextInput } from "melodies-source/TextInput";
import { useArtist } from "Providers/ArtistProvider";
import React, { useEffect, useState } from "react";
import { ImageUpload as ImageInput } from "Components/ImageUpload";
import styled from "styled-components";
import EditImageInput from "Components/Assets/Icons/editImageInput.svg?react";
import { useParams } from "react-router-dom";

export const ArtistOverview = () => {
  const { artist, overrides, setOverride, save } = useArtist();
  const { listId } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ArtistContainer>
      <H2>Artist Info</H2>
      <ModuleDetailsContent>
        {artist?.image ? (
          <ImageUpload>
            <ArtistImage src={artist?.image || ""} alt="" />
            <EditImageInput
              onClick={() => setIsOpen(true)}
              style={{
                position: "absolute",
                bottom: 7,
                right: 7,
                zIndex: 5,
                cursor: "pointer",
              }}
            />
          </ImageUpload>
        ) : (
          <ImageUpload>
            <ImageUploadField onClick={() => setIsOpen(true)}>
              <EditImageInput />
            </ImageUploadField>
          </ImageUpload>
        )}

        <div>
          <TextInput
            label="Artist Name"
            placeholder="Enter Artist Name"
            value={artist?.title ?? artist?.name}
            onChange={(v) => setOverride("title", v)}
          />
          <TextInput
            label="Primary Genre"
            placeholder="Enter Genre"
            value={artist?.primaryGenre}
            onChange={(v) => setOverride("primaryGenre", v)}
          />
          <Select
            onChange={(v) => setOverride("audience_size", v)}
            label="Career Stage"
            options={[
              { label: "Mainstream 1", value: "Mainstream 1" },
              { label: "Mainstream 2", value: "Mainstream 2" },
              { label: "Mainstream 3", value: "Mainstream 3" },
              { label: "Mainstream 4", value: "Mainstream 4" },
              {
                label: "Break-Through/Established 1",
                value: "Break-Through/Established 1",
              },
              {
                label: "Break-Through/Established 2",
                value: "Break-Through/Established 2",
              },
              {
                label: "Break-Through/Established 3",
                value: "Break-Through/Established 3",
              },
              { label: "Emerging 1", value: "Emerging 1" },
              { label: "Emerging 2", value: "Emerging 2" },
              { label: "Developing/Niche", value: "Developing/Niche" },
              { label: "Legacy", value: "Legacy" },
              { label: "Emerging", value: "Emerging" },
              { label: "Mainstream", value: "Mainstream" },
              {
                label: "Break-Through/Established",
                value: "Break-Through/Established",
              },
              {
                label: "Break-Through",
                value: "Break-Through",
              },
              {
                label: "Break-Through 1",
                value: "Break-Through 1",
              },
              {
                label: "Break-Through 2",
                value: "Break-Through 2",
              },
              {
                label: "Break-Through 3",
                value: "Break-Through 3",
              },
              {
                label: "Established",
                value: "Established",
              },
              {
                label: "Established 1",
                value: "Established 1",
              },
              {
                label: "Established 2",
                value: "Established 2",
              },
              {
                label: "Established 3",
                value: "Established 3",
              },
            ]}
            value={artist?.audience_size}
          />
          <Select
            onArrayChange={(v) => setOverride("eras", v)}
            label="Eras"
            options={[
              { label: "70s", value: "70s" },
              { label: "80s", value: "80s" },
              { label: "90s", value: "90s" },
              { label: "00s", value: "00s" },
              { label: "10s", value: "10s" },
              { label: "20s", value: "20s" },
            ]}
            valueArray={artist?.eras}
            style={{ marginTop: 10 }}
          />
        </div>
        <div>
          <TextInput
            label="Artist Site"
            placeholder="Enter Artist Site"
            value={artist?.services?.website?.url}
            onChange={(v) => setOverride("website.url", v)}
          />
          <TextInput
            label="Wikipedia"
            placeholder="Enter Wikipedia"
            value={artist?.services?.wikipedia?.url}
            onChange={(v) => setOverride("wikipedia.url", v)}
          />
          <Textarea
            label="Locations (one per line)"
            placeholder="Enter Location"
            value={
              overrides?.tempLocations ??
              artist?.locations?.map((location) => location.title).join("\n")
            }
            onChange={(v) => setOverride("tempLocations", v)}
          />
        </div>
      </ModuleDetailsContent>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} variant="small">
          <ImageInput
            uploadPath={`ame_sharelists/${listId}/`}
            onComplete={(url) => {
              setOverride("image", url);
              setIsOpen(false);
            }}
            aspectRatio={1}
            withAspectRatio
          />
        </Modal>
      )}
    </ArtistContainer>
  );
};

const ArtistContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 30px 30px 20px 30px;
  background-color: #f4f7f9;
  box-shadow: 0px 4px 20px 0px #d1d0d0;
  h2 {
    font-weight: 800;
    margin-bottom: 15px;
  }
  @media (max-width: 950px) {
    padding: 35px;
  }
`;

const ModuleDetailsContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  gap: 30px;
  input {
    margin-bottom: 10px;
    width: 100%;
  }
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    gap: 10px;
    textarea {
      height: 130px;
    }
  }
`;

const ImageUpload = styled.div`
  height: 290px;
  position: relative;
  aspect-ratio: 1/1;
  margin-top: 10px;
  background-color: #999;
  @media (max-width: 950px) {
    margin: 0 auto 0 0;
  }
`;

const ImageUploadField = styled.div`
  height: 290px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px;
  aspect-ratio: 1/1;
  border: 1px solid #d5d5d5;
  border-radius: 1px;
  background-color: #fff;
  cursor: pointer;
`;
const ArtistImage = styled.img`
  width: 290px;
  aspect-ratio: 1/1;
  object-fit: cover;
`;
