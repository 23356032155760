import Soundcloud from "Components/Assets/SoundCloud.png";
import { socials } from "Components/Assets/Icons/socials";
import styled from "styled-components";
import { Services, SocialService } from "Providers/ListProvider/types";
import { UpdatedArtist } from "Providers/ArtistProvider";

export const getListedServices = (artist: UpdatedArtist) => {
  return Object.keys(socialPlatformConfigs || {})
    .reduce((acc, key) => {
      if (
        artist?.services &&
        (key in artist.services ||
          (key === "tiktok" && !artist?.services.soundcloud))
      ) {
        const service = (artist?.services?.[key] || {}) as Services;
        if (
          key === "soundcloud" &&
          !(artist?.services?.soundcloud && !artist?.services?.tiktok)
        ) {
          return acc;
        }

        let hidden = artist?.followers?.[key]?.hidden === true;
        if (key === "tiktok" && !Object.keys(service).length) {
          hidden = true;
        }
        const fieldConfig = socialPlatformConfigs[key];
        acc.push({ ...service, ...fieldConfig, key, hidden });
      }
      return acc;
    }, [] as ServiceWithConfig[])
    .sort((a, b) => a.order - b.order);
};

export const formatMetricValue = (value: number | string) => {
  if (typeof value === "number") {
    return value.toLocaleString();
  }
  if (!Number.isNaN(Number(value))) {
    return Number(value).toLocaleString();
  }
  return value;
};

export const getMetricName = (service: ServiceWithConfig) => {
  if (!service?.key) return;
  if (service.highlight_metric) {
    return service.highlight_metric;
  }
  return socialPlatformConfigs[service.key].options[0];
};

const SocialIcon = styled.img`
  width: 100%;
  margin: 10px auto;
  object-fit: contain;
`;

export type ServiceWithConfig = SocialService & ServiceOption;

export type ServicesOnly = Omit<Services, "hidden" | "website" | "wikipedia">;

type Field = {
  label: string;
  placeholder: string;
  name: string;
};

export type ServiceOption = {
  label: string;
  key: keyof ServicesOnly;
  options: string[];
  disabledIcon: React.ReactNode;
  enabledIcon: React.ReactNode;
  enableAdd?: boolean;
  order: number;
  fields?: Field[];
};

type SocialPlatformConfig = {
  [key in keyof ServicesOnly]: ServiceOption;
};

export const socialPlatformConfigs: SocialPlatformConfig = {
  facebook: {
    order: 0,
    key: "facebook",
    label: "Facebook",
    options: ["likes", "followers"],
    disabledIcon: <socials.FacebookDark />,
    enabledIcon: <socials.FacebookColor />,
  },
  instagram: {
    order: 1,
    key: "instagram",
    label: "Instagram",
    options: ["followers"],
    disabledIcon: <socials.InstagramDark />,
    enabledIcon: <socials.InstagramColor />,
  },
  twitter: {
    order: 2,
    key: "twitter",
    label: "X",
    options: ["followers"],
    disabledIcon: <socials.XDark />,
    enabledIcon: <socials.XDark />,
  },
  spotify: {
    order: 3,
    key: "spotify",
    label: "Spotify",
    options: ["followers", "listeners"],
    disabledIcon: <socials.SpotifyDark />,
    enabledIcon: <socials.SpotifyColor />,
  },
  youtube: {
    order: 4,
    key: "youtube",
    label: "YouTube",
    options: ["views"],
    disabledIcon: <socials.YouTubeDark />,
    enabledIcon: <socials.YouTubeColor />,
  },
  tiktok: {
    order: 5,
    key: "tiktok",
    label: "TikTok",
    options: ["followers", "likes"],
    disabledIcon: <socials.TikTokDark />,
    enabledIcon: <socials.TikTokColor />,
    enableAdd: true,
    fields: [
      { name: "url", label: "TikTok URL", placeholder: "Enter TikTok URL" },
    ],
  },
  soundcloud: {
    order: 6,
    key: "soundcloud",
    label: "SoundCloud",
    options: ["followers"],
    disabledIcon: <SocialIcon src={Soundcloud} />,
    enabledIcon: <SocialIcon src={Soundcloud} />,
  },
};
